import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accessibility-statement"
    }}>{`Accessibility statement`}</h1>
    <p>{`This accessibility statement applies to the website Helsinki Developers. The site address is dev.hel.fi.`}</p>
    <h3 {...{
      "id": "statutory-provisions-applicable-to-the-website"
    }}>{`Statutory provisions applicable to the website`}</h3>
    <p>{`This website was published prior to 23/09/2018. The website must fulfil the statutory accessibility requirements after the transitional period ending on 23/09/2020.`}</p>
    <h3 {...{
      "id": "the-objective-of-the-city"
    }}>{`The objective of the city`}</h3>
    <p>{`As regards the accessibility of digital services, Helsinki aims to reach at least Level AA or above as set forth in the WCAG guidelines in so far as is reasonably practical.`}</p>
    <h3 {...{
      "id": "compliance-status"
    }}>{`Compliance status`}</h3>
    <p>{`The accessibility compliance of this website has not yet been evaluated.`}</p>
    <h3 {...{
      "id": "preparing-an-accessibility-statement"
    }}>{`Preparing an accessibility statement`}</h3>
    <p>{`This statement was prepared on 02/06/2020.`}</p>
    <h3 {...{
      "id": "updating-the-accessibility-statement"
    }}>{`Updating the accessibility statement`}</h3>
    <p>{`The accessibility statement will be updated so that it corresponds with the observations related to accessibility compliance made during an audit.`}</p>
    <h3 {...{
      "id": "requesting-information-in-an-accessible-format"
    }}>{`Requesting information in an accessible format`}</h3>
    <p>{`If a user feels that content on a website is not available in an accessible format, they can request for this information through the `}<a parentName="p" {...{
        "href": "https://www.hel.fi/helsinki/en/administration/participate/feedback"
      }}>{`feedback form`}</a>{`. The aim is to reply to the enquiry within a reasonable time frame.`}</p>
    <h3 {...{
      "id": "feedback-and-contact-information"
    }}>{`Feedback and contact information`}</h3>
    <p>{`City of Helsinki, City Executive Office, Software development team, `}<a parentName="p" {...{
        "href": "https://www.hel.fi/helsinki/en/administration/participate/feedback"
      }}>{`contact form`}</a>{`.`}</p>
    <h3 {...{
      "id": "the-city-of-helsinki-and-accessibility"
    }}>{`The City of Helsinki and accessibility`}</h3>
    <p>{`The objective of the city of Helsinki is to be an accessible city to all. Helsinki aims to ensure that all residents are able to move about and act as effortlessly as possible and that all content and services are accessible to all.`}</p>
    <p>{`The city promotes accessibility of digital services by streamlining publishing work and organising accessibility-related training for its staff.`}</p>
    <p>{`The accessibility level of websites is monitored constantly during their maintenance. Immediate action will be taken if deficiencies are found. The aim is to carry out the necessary amendments as quickly as possible.`}</p>
    <h3 {...{
      "id": "the-disabled-and-users-of-assistive-technologies"
    }}>{`The disabled and users of assistive technologies`}</h3>
    <p>{`The city provides counselling and support for the disabled and users of assistive technologies. Support is available on guidance sites announced on the city’s website and through telephone counselling.`}</p>
    <h3 {...{
      "id": "approval-of-the-accessibility-statement"
    }}>{`Approval of the accessibility statement`}</h3>
    <p>{`This statement was approved on 02/06/2020 by City Executive Office, City of Helsinki`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      